import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="ADTRAN’s Networking Solutions for Businesses"
        description="ADTRAN’s business networking solutions can scale from small businesses all the way up to fully distributed enterprises, in a manner that’s both quick and cost-effective."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">ADTRAN</Link>
          </BreadCrumb>
          <PageTitle>ADTRAN: Networking Equipment Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>ADTRAN</strong> makes products and services which make communications simpler and more affordable for people everywhere. They’ve been innovating and developing networking equipment since 1986, and ADTRAN’s vision is to enable a fully-connected world where the power to communicate is available to everyone, everywhere. ADTRAN produces business networking solutions, including routers, switches, IP business gateways, IoT gateways, business WiFi, fiber optic connections, terminals, & extensions, and other tools for network & configuration management.</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why ADTRAN?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>ADTRAN equipment keeps your network running smoothly, so you can spend more time focusing on what matters most – running your business and taking care of your customers! ADTRAN’s business networking solutions can scale from small businesses all the way up to fully-distributed enterprises, in a manner that’s both quick and cost-effective. Here are a few points which help to set ADTRAN apart:</p>
                <ul>
                  <li><strong>Completely Scalable</strong>
                    <p>ADTRAN offers products and solutions designed to meet the needs of networks of all sizes and scales.</p>
                  </li>
                  <li>
                    <strong>Competitive Pricing</strong>
                    <p>Enterprise-class quality of products, without the typical enterprise price tag.</p>
                    </li>
                  <li><strong>Intruder Detection & Protection</strong>
                    <p>ADTRAN products include a robust line of defense against any unwanted network threats.</p>
                  </li>
                  <li>
                    <strong>Reliable Product Support</strong>
                    <p>The ADTRAN team is recognized as an industry leader in delivering the networking product support you need.</p>
                  </li>
                </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of ADTRAN Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>ROUTERS</h3>
                <p>ADTRAN’s NetVanta Routers are capable instruments for delivering the power and performance you need for high-quality VoIP applications, as well as for other applications which rely upon a lot of available bandwidth. These routers are simple to set up, easy to maintain, and come at an attractive price point, too!</p>
                <h3>Multiservice Access Routers</h3>
                <ul>
                  <li>Supports an IP access router, Ethernet switch, firewall, and VPN</li>
                  <li>Single and dual NIM slots</li>
                  <li>8-port, Layer 2 Ethernet Switch</li>
                  <li>Standards-based RIP, OSPF, eBGP/iBGP routing protocols</li>
                  <li>Supports up to 100 IPSec VPN tunnels</li>
                </ul>
                <h3>Modular Routers</h3>
                <ul>
                  <li>For MPLS, Frame Relay, Multilink Frame Relay, PPP, Multilink PPP, PPPoE, PPPoA, RFC 1483, ADSL, and Ethernet-based networks</li>
                  <li>Single and dual NIM slots</li>
                  <li>Single and dual Ethernet</li>
                  <li>Standards-based RIP, OSPF, eBGP/iBGP routing protocols</li>
                  <li>Supports up to 1000 IPSec VPN tunnels</li>
                </ul>
                <h3>Fixed-Port Routers</h3>
                <ul>
                  <li>For high-performance broadband access</li>
                  <li>Three Gigabit Ethernet interfaces</li>
                  <li>Compatible with industry leading soft switches and call agents</li>
                  <li>Standards-based RIP, OSPF, eBGP/iBGP routing protocols</li>
                  <li>Supports up to 500 IPSec VPN tunnels</li>
                </ul>
                <h3>SWITCHES</h3>
                <p>ADTRAN’s high-performance Ethernet Switches offer advanced technology for delivering flawless VoIP, Power over Ethernet, and Gigabit capability for desktop and other applications. These switches accommodate up to 740 watts of total PoE power, and help provide for maximum network uptime.</p>
                <h3>Layer 3 Ethernet Switches</h3>
                <ul>
                  <li>Perfect for bandwidth-intensive and campus applications</li>
                  <li>8-, 24-, 48-port 10/100/1000BaseT Ethernet Ports</li>
                  <li>Up to 740 watts total PoE power</li>
                  <li>Non-blocking switching capacity up to 224 Gbps</li>
                  </ul>
                <h3>Layer 3 Lite Ethernet Switches</h3>
                <ul>
                  <li>Ideal for branch offices and SMBs</li>
                  <li>12-, 24-, 48- 10/100/1000BaseT Ethernet Ports</li>
                  <li>Up to 370 watts total PoE power</li>
                  <li>Non-blocking switching capacity up to 176 Gbps</li>
                  </ul>
                  <h3>IP BUSINESS GATEWAYS</h3>
                <p>ADTRAN Flexible IP Business Gateways deliver SIP-gateway functionality which includes a robust IP router, firewall, and VPN accommodations, and support for all sorts of analog and digital interfaces. Simply put, these gateways allow for seamless integration of voice and data applications across your network architecture.</p>
                <h3>Total Access 900 Series</h3>
                <ul>
                  <li>Single T1 SIP or MGCP Gateway</li>
                  <li>Single 10/100Base-T interface</li>
                  <li>Single DSX-1 for PRI/T1 CAS</li>
                </ul>
                <h3>Total Access 900e Series Gen 3</h3>
                <ul>
                  <li>Ethernet and Quad T1 Applications</li>
                  <li>Dual 10/100Base-T interfaces</li>
                  <li>One 10/100/1000BaseT interface</li>
                  <li>Lifeline FXO for local call/911 routing</li>
                  </ul>
                <h3>NetVanta 6250</h3>
                <ul>
                  <li>Multi-Ethernet/T1, Fiber-ready</li>
                  <li>Five routed Ethernet ports</li>
                  <li>Four T1 ports</li>
                  <li>Up to 24 analog POTS interfaces</li>
                  <li>VQM for advance troubleshooting</li>
                </ul>
                <h3>NetVanta 6310/6330</h3>
                <ul>
                  <li>SIP Gateway</li>
                  <li>Dual 10/100Base-T interfaces for routing, DMZ, or Ethernet-fed WAN</li>
                  <li>Single or dual-slot modular chassis</li>
                  <li>Integral PRI/PRA T1/E1 for PBX connectivity</li>
                  <li>ETSI compliant</li>
                  <li>Optional FXS and FXO interfaces</li>
                </ul>
                 <h3>BUSINESS WIFI</h3>
                <p>ADTRAN Cloud-Managed Business WiFi Solutions help you to build the business WiFi environment you need, at a price that also meets your organization’s budget. Start by creating your access points, then choose the cloud management option that suits you best.</p>
                <h3>Bluesocket Access Points</h3>
                <ul>
                  <li>Zero-Touch AP Provisioning</li>
                  <li>Enterprise-class performance, 100% cloud-managed Wi-Fi</li>
                  <li>7x24 monitored, geographically distributed, highly secure data centres</li>
                  <li>Concurrent dual band indoor and outdoor APs</li>
                  <li>Customizable notification/alert templates</li>
                  <li>Automatic channel and power adjustments to optimize coverage and maximize performance</li>
                  <li>Access to Interactive Support Community - 99.99% cloud uptime Service Level Agreement (SLA)</li>
                </ul>
                <h3>Express Cloud</h3>
                <ul>
                  <li>Centralized Cloud-Management</li>
                  <li>Real-time/Historical Reporting and Analytics Dashboard</li>
                  <li>Machine-Learning-Powered, Self Optimizing and Healing Technology</li>
                  <li>Seamless Layer 2 Mobility</li>
                  <li>Zero IT Setup</li>
                  <li>Guest Access with Customizable Captive Portals and Walled Garden</li>
                  <li>Context-Aware Security and Firewall</li>
                  <li>MAC, PSK, 802.1X Authentication</li>
                  <li>(Optional Upgrade) End user help desk</li>
                  </ul>
                <h3>Elite Cloud</h3>
                <p>Includes all the features offered by Express Cloud, PLUS the following:</p>
                <ul>
                  <li>Heatmaps for RF Planning and Rogue AP Location Tracking</li>
                  <li>Access to World-Class Support via phone/web with 1 hour priority SLA</li>
                  <li>Seamless Layer 3 Mobility</li>
                  <li>Wireless Intrusion Detection</li>
                  <li>RADIUS, LDAP/AD, SIP2 Web Authentication</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h3>IoT GATEWAYS</h3>
                <p>ADTRAN IoT Gateways are a perfect companion for your Smart Building applications. Leverage this IoT technology to improve building operations, reduce energy costs, support sustainability efforts, and increase the productivity of your facilities staff!</p>
                <ul>
                  <li><strong>ADTRAN 7310-08 Gateway</strong>
                    <p>ADTRAN’s first LoRaWAN Gateway is an 8-channel indoor gateway which is perfect for IoT applications both in and around your buildings. It’s small, powerful, easy to set up, and simple to configure through a Bluetooth app on your mobile device.</p>
                  </li>
                </ul>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Can Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>Tell us about your goals & needs for business networking equipment. Not only can WYN deliver and install the right ADTRAN product solution, but we can also provide Whatever You Need in terms of any other essential business technology solutions, too! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

    </Layout>
  )
}
export default BrandListPage
